import anime from 'animejs';
import * as Utils from 'utils';

export function defaultAnimation(target) {
    Utils.remove_anime(target);
    anime({
        targets: target,
        opacity: {
            value: [0, 1],
            duration: 600,
            easing: "easeInCirc",
        },
        translateY: {
            value: ['50px', 0],
            duration: 1000,
            delay: 100,
            easing: "easeOutExpo",
        },
        complete() {
            target.style.transform = '';
        }
    });
}

export function Observer(targets, callback) {
    const animElement = [].slice.call(targets);
    if ('IntersectionObserver' in window) {
        let Observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    callback(entry.target);
                    Observer.unobserve(entry.target);
                }
            });
        }, {
            root: null,
            rootMargin: '-10% 0px',
            threshold: 0
        });
        animElement.forEach(function (elements_animation) {
            Observer.observe(elements_animation);
        });
    }
}

export default () => {
    Observer(document.querySelectorAll('[data-anim_element]'), defaultAnimation)
}