import 'intersection-observer';
import 'whatwg-fetch';

import contentLoaded from './content_loaded';
import Load from './load';

import includes_polyfill from './polyfill/includes';
includes_polyfill();

document.addEventListener('DOMContentLoaded', () => {
    contentLoaded();
})

window.addEventListener('load', () => {
    Load();
})