import * as Utils from 'utils';

export default () => {
    const qa_items = [].slice.call(document.querySelectorAll('.content_list__item'));
    qa_items.forEach(qa_item => {
        const content_item_question = qa_item.querySelector('.content_item_question');
        const content_item_answer = qa_item.querySelector('.content_item_answer');

        if (content_item_question && content_item_answer) {
            content_item_question.addEventListener('click', () => {
                Utils.slideToggle(content_item_answer, 300);
                qa_item.classList.toggle('is-open');
            })
        }

    });
}