import anime from 'animejs';
import * as Utils from 'utils';

export default () => {
    let menu_flag = false;
    const sp_nav_btn = document.querySelector('.sp_nav_btn');
    const button = sp_nav_btn.querySelector('button');
    const sp_nav_menu = document.querySelector('.sp_nav_menu') as HTMLElement;

    const fadeIn = () => {
        Utils.remove_anime(sp_nav_menu);
        anime({
            targets: sp_nav_menu,
            opacity: {
                value: 1,
                duration: 800,
                easing: "easeOutQuint",
            },
            begin: function () {
                sp_nav_menu.style.display = 'block';
            },
        });
    }

    const fadeOut = () => {
        Utils.remove_anime(sp_nav_menu);
        anime({
            targets: sp_nav_menu,
            opacity: {
                value: 0,
                duration: 600,
                easing: "easeOutQuint",
            },
            complete: function () {
                sp_nav_menu.style.display = 'none';
            },
        });
    }

    button.addEventListener('click', () => {
        sp_nav_btn.classList.toggle('is_open');
        if (menu_flag) {
            fadeOut();
            menu_flag = false;
        } else {
            fadeIn();
            menu_flag = true;
        }
    })
}