import 'smoothscroll-polyfill';
import * as Utils from 'utils';

export function smooth(...args) {
    let target = null;
    const fromTarget = args[0].from && Utils.isElement(args[0].from) ? args[0].from : document.querySelector(args[0].from);
    const toTarget = args[0].to && Utils.isElement(args[0].to) ? args[0].to : document.querySelector(args[0].to);
    const toPosition = args[0].toPosition;
    const offset = args[0].offset || 0;

    const windowScroll = (top = 0) => {
        window.scroll({
            top: top,
            left: 0,
            behavior: 'smooth'
        });
    }

    if (fromTarget) {
        const href = fromTarget.getAttribute('href');
        if (href == '#') {
            windowScroll();
            return false;
        } else {
            const elm = document.querySelector(href);
            if (elm) target = elm;
            else return false;
        }
    } else if (toTarget) {
        target = toTarget
    } else if (toPosition) {
        windowScroll(toPosition)
    } else return false;

    if (target) target.scrollIntoView({
        behavior: 'smooth'
    });
}

export function addSmoothEvent(...args) {
    const params = args[0];
    if (!params['triggers']) return false;
    const triggers = [].slice.call(document.querySelectorAll(params['triggers']));

    triggers.forEach(element => {
        element.addEventListener('click', (e) => {
            e.preventDefault();
            smooth({
                from: element
            })
        });
    });
}

export default {
    addSmoothEvent,
}