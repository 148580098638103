import Choices from 'choices.js';
import * as Utils from 'utils';

export default () => {
    const data_js_choices = document.querySelector('[data-js-choices]') as HTMLSelectElement;
    if (data_js_choices) {
        const choices = new Choices(data_js_choices, {
            searchEnabled: false,
            itemSelectText: '選択',
            removeItemButton: true,
        });

        choices.passedElement.element.addEventListener('change', (choice) => {
            const _choice = choice as any;
            const value = _choice.detail.value;
            const param = Utils.insertQuery({
                'cat': value
            });
            location.href = location.pathname + param;
        })
    }
}