import anime from 'animejs';
import content_loaded from '../../content_loaded';

export function visibilityCheck(target) {
    const style = getComputedStyle(target)

    if (target.offsetHeight === 0 ||
        style.display === 'none' ||
        style.visibility !== 'visible' ||
        parseFloat(style.opacity || '') <= 0.0 ||
        parseInt(style.height || '', 10) <= 0 ||
        parseInt(style.width || '', 10) <= 0
    ) {
        return false
    }
    return true
}

export function elements_visible_check(elments) {
    for (let i = 0; i < elments.length; i++) {
        if (visibilityCheck(elments[i])) {
            return false;
        }
    }
    return true;
}

export function isObject(o) {
    return typeof o === 'object' && o !== null && o.constructor && o.constructor === Object;
}

export function isString(o) {
    return typeof (o) == "string" || o instanceof String;
}

export function isElement(o) {
    try {
        return o instanceof HTMLElement;
    } catch (e) {
        return (typeof o === "object") &&
            (o.nodeType === 1) && (typeof o.style === "object") &&
            (typeof o.ownerDocument === "object");
    }
}

export function remove_anime(...elm) {
    for (let i = 0; i < elm.length; i++) {
        anime.remove(elm[i])
    }
}

export function extend(...args) {
    const to = Object(args[0]);
    for (let i = 1; i < args.length; i += 1) {
        const nextSource = args[i];
        if (nextSource !== undefined && nextSource !== null) {
            const keysArray = Object.keys(Object(nextSource));
            for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex += 1) {
                const nextKey = keysArray[nextIndex];
                const desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                if (desc !== undefined && desc.enumerable) {
                    if (isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
                        extend(to[nextKey], nextSource[nextKey]);
                    } else if (!isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
                        to[nextKey] = {};
                        extend(to[nextKey], nextSource[nextKey]);
                    } else {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
    }
    return to;
}

export function removeFalsy(obj) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
            newObj[prop] = obj[prop];
        }
    });
    return newObj;
};

export function isEmpty(val) {
    if (!val) {
        if (val !== 0 && val !== false) {
            return true;
        }
    } else if (typeof val == "object") {
        return Object.keys(val).length === 0;
    }
    return false;
}


export function getScrollY(window) {
    if ('scrollY' in window) return window.scrollY;
    if ('pageYOffset' in window) return window.pageYOffset;
    const doc = window.document;
    return doc.compatMode === 'CSS1Compat' ? doc.documentElement.scrollTop : doc.body.scrollTop;
}

export function script_name_replace(this_filename, replace_filename) {
    let search_result;

    const script_dom = document.getElementsByTagName('script');
    if (!script_dom) return false;
    for (let i = 0; i < script_dom.length; i++) {
        const src = script_dom[i].getAttribute('src');
        if (!src || src.indexOf(this_filename) === -1) continue;
        search_result = src.replace(this_filename, replace_filename);
        break;
    }

    return search_result ? search_result : false
};

export function xmlToJson(xml) {

    // Create the return object
    let obj = {};

    if (xml.nodeType == 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (let j = 0; j < xml.attributes.length; j++) {
                const attribute = xml.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType == 3) { // text
        obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
            let item = xml.childNodes.item(i);
            let nodeName = item.nodeName;
            if (nodeName == '#text') nodeName = '_text';

            if (typeof (obj[nodeName]) == "undefined") {
                obj[nodeName] = xmlToJson(item);
            } else {
                if (typeof (obj[nodeName].push) == "undefined") {
                    let old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xmlToJson(item));
            }
        }
    }
    return obj;
};

export function duplicationDelete(arr) {
    return arr.filter((x, i, self) => self.indexOf(x) === i);
}

export function removeElement(element) {
    element.parentNode.removeChild(element);
}

export function unwrap(target) {
    while (target.firstChild) {
        target.parentNode.insertBefore(target.firstChild, target);
    }
    target.remove();
}

export function searchElement(target, query) {
    let array = [];
    const elements = [].slice.call(target);
    elements.forEach(element => {
        const target_element = [].slice.call(element.querySelectorAll(query));
        target_element.forEach(value => {
            array.push(value);
        });
    });
    return array;
}

export function _try(func, fallbackValue) {
    try {
        var value = func();
        return (value === null || value === undefined) ? fallbackValue : value;
    } catch (e) {
        return fallbackValue;
    }
}

export function getQuery() {
    let query = {};
    let queryList = document.location.search.substr(1).split('&');
    queryList = queryList.filter(i => i);

    if (!isEmpty(queryList)) {
        queryList.forEach(queryItem => {
            const split_query = queryItem.split('=');
            query[split_query[0]] = split_query[1] || '';
        });
    }

    return query;
}

export function insertQuery(queryObj, url: '') {
    let queryString = '';
    let query = {};
    let queryList = document.location.search.substr(1).split('&');
    queryList = queryList.filter(i => i);

    if (!isEmpty(queryList)) {
        queryList.forEach(queryItem => {
            const split_query = queryItem.split('=');
            query[split_query[0]] = split_query[1] || '';
        });
    }

    if (queryObj) query = extend(extend, queryObj);
    query = removeFalsy(query);

    for (let key of Object.keys(query)) {
        const value = query[key];
        queryString += '&';
        queryString += key;
        if (value) queryString += '=' + value;
    }

    queryString = queryString.substr(1);
    return queryString ? encodeURI('?' + queryString) : '';
}

export function arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export function arrayCheck(args) {
    let array = [];
    if (!Array.isArray(args)) array.push(args);
    return array.length ? array : args;
}

export function slideUp(target, duration = 500) {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

export function slideDown(target, duration = 500) {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none'){
        if(target.dataset.slideDisplay) display = target.dataset.slideDisplay;
        else display = 'block';
    }

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

export function slideToggle(target, duration = 500) {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}