import hamburger from './component/hamburger/hamburger';
import height_sort from './component/height_sort/height_sort';
import faq from './component/faq/faq';
import scroll_hint from './component/scroll-hint/scroll-hint';
import choices from './component/choices/choices';
import scroll_animation from './component/scroll_animation/scroll_animation';
import smooth_scroll from './component/smooth_scroll/smooth_scroll'

export default () => {
    hamburger();
    height_sort();
    if (document.querySelector('.faq_page')) faq();
    else if (document.querySelector('.works_page')) choices();
    // else if (document.querySelector('.feature_page')) scroll_hint();
    scroll_animation();
    smooth_scroll.addSmoothEvent({
        triggers: 'a[href^="#"]'
    });
}